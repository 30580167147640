import { render, staticRenderFns } from "./MachineryDetails.vue?vue&type=template&id=5cf98e7c&scoped=true&"
import script from "./MachineryDetails.vue?vue&type=script&lang=js&"
export * from "./MachineryDetails.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cf98e7c",
  null
  
)

export default component.exports